import React from 'react';
import './App.css';
import logo from './logo.svg'; // Assurez-vous que ce chemin est correct pour votre fichier de logo

function App() {
  return (
    <div className="App">
      <header className="navbar">
        <nav>
          <ul className="nav-links">
            <li className="social-icons">
              <a href="https://www.instagram.com/table46.beloeil" target="_blank" rel="noopener noreferrer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" alt="Instagram" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61560585865304" target="_blank" rel="noopener noreferrer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" alt="Facebook" />
              </a>
              <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">
                <img src="https://upload.wikimedia.org/wikipedia/en/a/a9/TikTok_logo.svg" className="tiktok-logo" alt="TikTok" />
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <section id="accueil" className="home-section">
          <div className="logo-container">
            <div className="glitch">
              <img src={logo} alt="Table 46 Logo" className="logo" />
            </div>
          </div>
          <div className="pdf-link-container">
            <a href="/dineInMenu.pdf" target="_blank" rel="noopener noreferrer" className="pdf-link">Menu Salle à Manger</a>
            <a href="/deliveryMenu.pdf" target="_blank" rel="noopener noreferrer" className="pdf-link">Menu Livraison</a>
            <a href="/menuTravailleur.pdf" target="_blank" rel="noopener noreferrer" className="pdf-link">Menu Travailleur</a>
          </div>
        </section>
      </main>
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </div>
  );
}

export default App;
